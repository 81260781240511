.card {
  &.card__auth{
    margin-top:60px;
    text-align: center;

    img{
      width: 40%;
      margin: 20spx;
    }

    .lead{
      font-size: 20px;
      margin-bottom: 40px;
    }

    .authForm{
      margin: 20px 40px;
    }

    .confirmationMessage{
      margin-top:20px;
      text-align: left;
    }
  }
}