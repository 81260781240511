.wrapper__kudo{
  .intro{
    margin-top:60px;
    margin-bottom:60px;
    text-align: center;

    img.logo{
      width: 40%;
    }

    .lead{
      font-size: 22px;
    }

    .authForm{
      margin: 40px;
    }
  }
}