html, body{
  background-color:#f0f2f5;
}

.card {
  border: 1px solid #eee;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 20px;
  padding: 30px;
  box-shadow:rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.wrapper {
  width: 90%;
  max-width: 550px;
  margin: 20px auto;
}